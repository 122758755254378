import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Clients(){
    var settings = {
        dots: false,
        infinite: true,
        speed: 6000,
        slidesToShow: 7,
        slidesToScroll: 1,
        cssEase: 'linear',
        autoplaySpeed: 0,
        autoplay: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 6,
            }
          },
    
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 5,
            }
          },

          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
            }
          },
        ]
      };

    return <div className="max-sm:mb-20">
        <div className="flex max-md:flex-col max-md:gap-10 max-sm:gap-8 justify-center items-center px-20 mt-4 overflow-hidden">
            <div className="relative md:left-[18vw] *:py-1 flex justify-around flex-col text-[2.4vw] max-md:text-[30px] max-w-[400px] text-[#FFFFFF80] bg-gradient-to-r from-[#000000ff_75%] to-[#000000B3] z-[-1] ">
                <span className="whitespace-nowrap flex justify-start max-md:justify-center">
                    Our team raised  
                </span>
                <span className="whitespace-nowrap flex justify-start max-md:justify-center">
                    over 
                    &nbsp;
                    <span className="text-white relative font-bold">
                        $6.5 Million 
                        <div className="h-1 w-full bg-[#627ECA] absolute bottom-[5%] right-[-5px] rounded-[12px] skew-y-[-2.5deg]"></div>
                    </span>
                </span>
                <span className="whitespace-nowrap flex justify-start max-md:justify-center">
                    for Web3 clients
                </span>
            </div>

            <Slider className="client-slider z-[-1] w-[100vw] max-md:w-[120vw] lg:left-[4vw] sm:left-0 md:left-0" {...settings}>
                <img loading="lazy"  src="clients/0.png" />
                <img loading="lazy"  src="clients/1.png" />
                <img loading="lazy"  src="clients/2.png" />
                <img loading="lazy"  src="clients/3.png" />
                <img loading="lazy"  src="clients/4.png" />
                <img loading="lazy"  src="clients/5.png" />
                <img loading="lazy"  src="clients/6.png" />
                <img loading="lazy"  src="clients/7.png" />
                <img loading="lazy"  src="clients/8.png" />
                <img loading="lazy"  src="clients/9.png" /> 
            </Slider>

        </div>
    </div>
}