import React, { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const options = {method: 'GET', headers: {accept: 'application/json'}};

export default function ComingSoon(){
    const [menuOpen, setMenuOpen] = useState(false);
    const [isFixed, setIsFixed] = useState(false);

    return <div className='text-white font-poppins min-h-[100vh] flex flex-col'>
        {isFixed || menuOpen ? <div className="h-[88.1px] w-full"></div> : <></>}
        <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} selectedItem={5}/>

        <div className="flex justify-center items-center overflow-clip relative grow z-[-1]">
            {/* golden gradient */}
            <div className="bg-[#FFC385] opacity-20 max-sm:blur-[180px] blur-[200px] h-[50%] min-sm:h-[40%] w-[60%] max-w-[820px] min-w-[320px] absolute left-[-30%] top-[25%]"></div>

            <img loading="lazy"  className="w-[50vw] py-24 max-sm:w-[90vw] max-md:w-[75vw]" src="/coming soon.png"/>

            {/* blue gradient */}
            <div className="bg-[#627ECA] opacity-20 max-sm:blur-[130px] blur-[150px] h-[50%] min-sm:h-[40%] w-[60%] max-w-[820px] min-w-[320px] absolute right-[-30%] top-[25%]"></div>
        </div>

        <Footer/>
    </div>
}
