import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home'
import CaseStudies from './pages/CaseStudies'
import ComingSoon from './pages/ComingSoon'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="/NFTs" element={<ComingSoon />} />
          <Route path="/DEFI" element={<ComingSoon />} />
        </Route>

        <Route path="/case-studies">
          <Route path="BLOCK9" element={<CaseStudies i={0} />} />
          <Route path="PHANTOM-MAGES" element={<CaseStudies i={1} />} />
          <Route path="NEXI-LABS" element={<CaseStudies i={2} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
