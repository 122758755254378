import React, { useState } from "react";

export default function Faq(){
    const [q1Active, setq1Active] = useState(false);
    const [q2Active, setq2Active] = useState(false);
    const [q3Active, setq3Active] = useState(false);
    const [q4Active, setq4Active] = useState(false);

    return <div className="faqs-query-parent p-16 max-sm:p-5 flex justify-center relative mt-3 increase-margin-on-collapse">
        <img loading="lazy"  className="w-screen h-[70%] opacity-60 object-cover absolute top-[-115px] blur-sm max-w-[1800px] z-[-2]" src="net.png" />

        <div className="flex max-[1400px]:flex-col gap-14 bg-[#627ECA1A] w-full rounded-[50px] max-sm:px-8 max-sm:py-[40px] py-[35px] px-14 font-bold z-[-1] max-w-[1500px] faqs-query-970 max-sm:hidden">
            <div className="flex flex-col justify-between">
                <span className="text-5xl max-sm:text-3xl text-[#FFFFFF80]">
                    FAQs
                </span>

                <div className="flex flex-col text-[3.7rem] max-sm:text-[2.5rem] leading-[100%] pt-6 max-md:pr-0">
                    <span className="text-[#627ECA] lg:pr-40">
                        Don’t
                        worry 
                    </span>

                    <span className="text-[#CDC8B6] font-playfair mt-8 max-md:mt-1 ">
                        We’ve got you covered!
                    </span>
                </div>
            </div>

            <div className="*:overflow-clip hover:*:cursor-pointer max-[1400px]:justify-around *:h-[300px] *:rounded-[15px] *:w-[350px] *:max-[980px]:w-[48%] *:max-[860px]:w-[100%] *:max-w-[500px] flex gap-6 justify-end flex-wrap">
                
                
                <div className="text-[#FFFFFF80] pointer-events-auto relative" onClick={()=> setq1Active(!q1Active)}>
                    <div className={`h-full py-6 w-full flex gap-5 items-start absolute bg-[#24272D] rounded-[15px] p-6 duration-[1s] ${q1Active ? "opacity-100" : "opacity-50"}`}>
                        <img loading="lazy"  className="h-[40px] mt-1" src="lightbulb.png" />

                        <div className="flex gap-5 flex-col max-sm:text-sm">
                            <span>
                                What’s so special about Visionary studios compared to other agencies?
                            </span>

                            <span className="text-[13.5px] font-normal max-sm:text-[12.5px]">
                        Visionary Studios has influenced an extraordinary number of projects to succeed through industry-low rates, precise custom-tailored campaigns, authentic consultation, and most importantly, experience. We’ve truly cooked up the magic sauce, no bullsh*t attached.
                            </span>
                        </div>
                    </div>

                    <div className={`text-[1.35rem] h-full flex flex-col items-start justify-between relative bg-gradient-to-tl from-[#40548A] to-[#0C1016] rounded-[15px] p-6 ${q1Active ? "top-[100%]" : "top-0"} duration-[1s]`}>
                        <img loading="lazy"  className="h-[51px]" src="question mark.png" />

                        <span>
                            Why Visionary?
                        </span>
                    </div>
                </div>

                <div className="text-[#FFFFFF80] pointer-events-auto relative" onClick={()=> setq2Active(!q2Active)}>
                    <div className={`h-full py-6 w-full flex gap-5 items-start absolute bg-[#24272D] rounded-[15px] p-6 duration-[1s] ${q2Active ? "opacity-100" : "opacity-50"}`}>
                        <img loading="lazy"  className="h-[40px] mt-1" src="lightbulb.png" />

                        <div className="flex gap-5 flex-col max-sm:text-sm">
                            <span>
                                What can we expect through onboarding Visionary?
                            </span>

                            <span className="text-[13.5px] font-normal max-sm:text-[12.5px]">
                          We are the all-in-one package you’ve been searching for, handling everything from marketing and general advisory to finding KOLs best suited for your project. Expect the best; we are committed to getting deeply involved in every aspect of your project.
                            </span>
                        </div>
                    </div>

                    <div className={`text-[1.35rem] h-full flex flex-col items-start justify-between relative bg-gradient-to-tl from-[#40548A] to-[#0C1016] rounded-[15px] p-6 ${q2Active ? "top-[100%]" : "top-0"} duration-[1s]`}>
                        <img loading="lazy"  className="h-[51px]" src="question mark.png" />

                        <span>
                            What to expect through onboarding Visionary?
                        </span>
                    </div>
                </div>




                <div className="text-[#FFFFFF80] pointer-events-auto relative" onClick={()=> setq3Active(!q3Active)}>
                    <div className={`h-full py-6 w-full flex gap-5 items-start absolute bg-[#24272D] rounded-[15px] p-6 duration-[1s] ${q3Active ? "opacity-100" : "opacity-50"}`}>
                        <img loading="lazy"  className="h-[40px] mt-1" src="lightbulb.png" />

                        <div className="flex gap-5 flex-col max-sm:text-sm">
                            <span>
                                How big should our budget be for marketing?
                            </span>

                            <span className="text-[13.5px] font-normal max-sm:text-[12.5px]">
                                We excel in the realm of organic marketing, enabling you to increase your followers and engagement at industry low rates. Once we assess your project, we can furnish a customized quotation aimed at providing you the best value for buck.
                            </span>
                        </div>
                    </div>

                    <div className={`text-[1.35rem] h-full flex flex-col items-start justify-between relative bg-gradient-to-tl from-[#40548A] to-[#0C1016] rounded-[15px] p-6 ${q3Active ? "top-[100%]" : "top-0"} duration-[1s]`}>
                        <img loading="lazy"  className="h-[51px]" src="question mark.png" />

                        <span>
                            How much does it cost?
                        </span>
                    </div>
                </div>




                <div className="text-[#FFFFFF80] pointer-events-auto relative" onClick={()=> setq4Active(!q4Active)}>
                    <div className={`h-full py-6 w-full flex gap-5 items-start absolute bg-[#24272D] rounded-[15px] p-6 duration-[1s] ${q4Active ? "opacity-100" : "opacity-50"}`}>
                        <img loading="lazy"  className="h-[40px] mt-1" src="lightbulb.png" />

                        <div className="flex gap-5 flex-col max-sm:text-sm">
                            <span>
                                How can you contact us?
                            </span>

                            <span className="text-[13.5px] font-normal max-sm:text-[12.5px]">
                                Click <a className="text-[#627ECA] underline" href="/">here</a>, it's that easy!
                            </span>
                        </div>
                    </div>

                    <div className={`text-[1.35rem] h-full flex flex-col items-start justify-between relative bg-gradient-to-tl from-[#40548A] to-[#0C1016] rounded-[15px] p-6 ${q4Active ? "top-[100%]" : "top-0"} duration-[1s]`}>
                        <img loading="lazy"  className="h-[51px]" src="question mark.png" />

                        <span>
                            How can you contact us?
                        </span>
                    </div>
                </div>

                
            </div>
        </div>

        <div className="hidden max-sm:flex flex-col items-center gap-4 z-[-1]">
            <span className="text-[2.7rem] text-[#CDC8B6] font-playfair italic flex line-h text-center font-bold">
                Frequently Asked Questions
            </span>

            <span className="text-[#FFFFFF80] text-lg">
                How can we help you?
            </span>

            <div className="flex flex-col gap-4">
                {/* q1 */}
                <div onClick={()=>{ setq1Active(!q1Active); setq2Active(false); setq3Active(false); setq4Active(false)}} className={`overflow-clip rounded-[12px] ${!q1Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000] h-[75px]" : "h-auto"}`}>
                    <div className={`flex flex-col rounded-[12px] px-6 py-6 ${q1Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000_150%]" : ""}`}>
                        <span className="font-semibold text-lg">
                            Why Visionary?
                        </span>

                        <span className="text-sm opacity-50 font-light mt-7">
                            Visionary studios has influenced an extraordinary amount ofprojects to succeed through industry low rates, precise custom tailoredcampaigns, authentic consultation & most importantly.. Experience. We’vetruly cooked up the magic sauce, no bullsh*t attached.
                        </span>
                    </div>
                </div>


                {/* q2 */}
                <div onClick={()=>{ setq2Active(!q2Active); setq1Active(false); setq3Active(false); setq4Active(false)}} className={`overflow-clip rounded-[12px] ${!q2Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000] h-[100px]" : "h-auto"}`}>
                    <div className={`flex flex-col rounded-[12px] px-6 py-6 ${q2Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000_150%]" : ""}`}>
                        <span className="font-semibold text-lg">
                            What to expect through onboarding Visionary?
                        </span>

                        <span className="text-sm opacity-50 font-light mt-7">
                            We are the all in one package you’ve been searching for. All the way from taking care of your marketing, general advisory to leading your collaboration strategies. Expect the best, we like to get our hands dirty
                        </span>
                    </div>
                </div>


                {/* q3 */}
                <div onClick={()=>{ setq3Active(!q3Active); setq1Active(false); setq2Active(false); setq4Active(false)}} className={`overflow-clip rounded-[12px] ${!q3Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000] h-[75px]" : "h-auto"}`}>
                    <div className={`flex flex-col rounded-[12px] px-6 py-6 ${q3Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000_150%]" : ""}`}>
                        <span className="font-semibold text-lg">
                            How much does it cost?
                        </span>

                        <span className="text-sm opacity-50 font-light mt-7">
                            We excel in the realm of organic marketing, enabling you to increase your followers and engagement at industry low rates. Once we assess your project, we can furnish a customized quotation aimed at providing you the best value for buck.
                        </span>
                    </div>
                </div>


                {/* q4 */}
                <div onClick={()=>{ setq4Active(!q4Active); setq1Active(false); setq2Active(false); setq3Active(false)}} className={`overflow-clip rounded-[12px] ${!q4Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000] h-[75px]" : "h-auto"}`}>
                    <div className={`flex flex-col rounded-[12px] px-6 py-6 ${q4Active ? "bg-gradient-to-br from-[#2A2A2B] to-[#000000_150%]" : ""}`}>
                        <span className="font-semibold text-lg">
                            How can you contact us?
                        </span>

                        <span className="text-sm opacity-50 font-light my-7">
                            Click <a className="text-[#627ECA] underline" href="/">here</a>, it's that easy!
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
