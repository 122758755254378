import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Navbar(props){
    const location = useLocation();
    const [navbarFixed, setNavbarFixed] = useState("");

    useEffect(() => {
      const href = window.location.href.substring(
        window.location.href.lastIndexOf('#') + 1
      );
      const element = document.getElementById(href);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
          if(window.scrollY > window.innerHeight){
            setNavbarFixed("fixed top-0 bg-[#000000CC] backdrop-blur-sm");
          }else{
            setNavbarFixed("");
          }
        };
  
        window.addEventListener("scroll", handleScroll);
  
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);

    return <div className={`flex items-center justify-between p-6 whitespace-nowrap w-full ${navbarFixed} ${props.menuOpen ? "fixed backdrop-blur top-0" : ""} z-100`}>
      {props.menuOpen ? <img loading="lazy"  onClick={() => props.setMenuOpen(false)} className="cursor-pointer pointer-events-auto absolute left-[26%] z-10 h-[28px]" src="/close.png"/> : <></>}
      <img loading="lazy"  className='pointer-events-auto h-[39px] cursor-pointer' src='/logo.png'/>
      <div className={`pointer-events-auto *:opacity-50 flex gap-10 font-semibold text-[16px]  min-[1900px]:text-[24px] ${props.menuOpen ? "flex-col absolute right-0 top-0 h-screen w-[80%] items-center justify-center text-[1.5rem] bg-[#000000E6] rounded-l-[23px] z-100" : "max-[1090px]:hidden"} hover:*:opacity-100 hover:*:cursor-pointer`}>
        <a className={`${props.selectedItem == 1 ? "text-[#627ECA] opaque" : ""}`} onClick={() => props.setMenuOpen(false)} href="/#home">
          HOME
        </a>

        <a className={`${props.selectedItem == 2 ? "text-[#627ECA] opaque" : ""}`} onClick={() => props.setMenuOpen(false)} href={`/case-studies/${location.pathname.split("/")[1] == "case-studies" ? location.pathname.split("/")[2] : "BLOCK9"}#`}>
          CASE STUDIES
        </a>

        <a onClick={() => props.setMenuOpen(false)} href="/#services">
          SERVICES
        </a>

        <a onClick={() => props.setMenuOpen(false)} href="/#testimonials">
          TESTIMONIALS
        </a>

        <a className={`${props.selectedItem == 5 ? "text-[#627ECA] opaque" : ""}`} onClick={() => props.setMenuOpen(false)} href="/NFTs/#">
          NFTs
        </a>

        <a onClick={() => props.setMenuOpen(false)} href="/DEFI">
          DEFI
        </a>
      </div>

      <div className="flex items-center">
        <a href="https://calendly.com/visionarystudios/30min">
          <div className='max-[1090px]:mr-7 max-sm:mr-5 pointer-events-auto rounded-[57px] border py-2 max-sm:py-1.5 px-6 text-[15px] hover:text-black hover:bg-white cursor-pointer'>
            LET’S CHAT!
          </div>
        </a>

        <img loading="lazy"  onClick={() => props.setMenuOpen(!props.menuOpen)} className="pointer-events-auto cursor-pointer w-[32px] h-[32px] max-[1090px]:visible collapse" src="/hamburger menu.png"/>
      </div>
    </div>   
}