import Landing from '../components/Landing'
import Section2 from '../components/Section2'
import Services from '../components/Services';
import Clients from '../components/Clients'
import Philosophy from '../components/Philosophy'
import CaseStudies from '../components/CaseStudies'
import Testimonials from '../components/Testimonials'
import Faq from '../components/Faq';
import ReadyToStart from '../components/ReadyToStart';
import Footer from '../components/Footer'

export default function Home(){
    return <div className='text-white font-poppins'>
        <Landing/>
        <Section2/>
        <Services/>
        <Clients/>
        <Philosophy/>
        <CaseStudies/>
        <Testimonials/>
        <Faq/>
        <ReadyToStart/>
        <Footer/>
    </div>
}