import React, { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import OtherCaseStudies from "../components/OtherCaseStudies";
import ReadyToStart from "../components/ReadyToStart";
import Footer from "../components/Footer";
import caseStudiesData from "../data/case studies";
import SimpleSlider from "../components/SimpleSlider";

export default function CaseStudies({ i }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const resizeW = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", resizeW); // Update the width on resize

    return () => window.removeEventListener("resize", resizeW);
  });

  console.log(caseStudiesData[i].projectName);

  return (
    <div className="text-white font-poppins relative">
      {isFixed || menuOpen ? <div className="h-[88.1px]"></div> : <></>}

      <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} selectedItem={2} />
      <div
        className={`relative flex flex-col gap-8 px-24 max-md:px-10 max-sm:px-6 pt-16 max-sm:pt-7 pb-24 max-md:pb-20 max-sm:pb-14 ${caseStudiesData[i].bannerImage} bg-cover fade-bg-y bg-opacity-30 bg-no-repeat z-[-1]`}
      >
        {/* fade bg image */}
        <div className="absolute h-full w-full bg-[#000000] opacity-70 top-0 left-0 z-[-1]"></div>

        <div className="flex case-920-col items-center gap-8">
          <span className="text-8xl font-semibold text-[#CDC8B6] max-md:text-6xl">
            {caseStudiesData[i].projectName}
          </span>

          <div className="*:whitespace-nowrap flex max-sm:text-sm font-semibold text-md gap-3 *:text-[#C0CBEA] *:rounded-[30px] *:border-[1.5px] *:border-[#627ECA] *:py-2 *:px-7 collapse-on-400">
            <div>{caseStudiesData[i].responsibilities[0]}</div>

            <div>{caseStudiesData[i].responsibilities[1]}</div>
          </div>
        </div>

        <span className="font-medium text-lg max-w-[900px]">
          {caseStudiesData[i].description}
        </span>

        <div className="flex flex-wrap max-[680px]:flex-col gap-9 max-sm:mt-3 max-sm:gap-3">
          <div className="flex flex-col gap-3">
            <span className="text-5xl max-md:text-4xl max-sm:text-3xl font-bold">
              {caseStudiesData[i].campaignLength}
            </span>

            <span className="text-xl opacity-50">campaign length</span>
          </div>

          <div className="w-[1.5px] rounded-[2px] bg-[#627ECA80]"></div>

          <div className="flex flex-col gap-3">
            <span className="text-5xl max-md:text-4xl max-sm:text-3xl font-bold">
              {caseStudiesData[i].nftSold}
            </span>

            <span className="text-xl opacity-50">NFTS SOLD AT 1.5 SOL</span>
          </div>

          <div className="w-[1.5px] rounded-[2px] bg-[#627ECA80]"></div>

          <div className="flex flex-col gap-3">
            <span className="text-5xl max-md:text-4xl max-sm:text-3xl font-bold">
              {caseStudiesData[i].totalRaised}
            </span>

            <span className="text-xl opacity-50">TOTAL RAISE</span>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-5 px-24 max-md:px-10 max-sm:px-6 max-md:py-10 py-16 border-y border-[#627ECA40]">
        <span className="font-bold text-[#627ECA] text-4xl max-md:text-3xl">
          Challenges & Objectives
        </span>

        <span className="font-md text-lg min-xl:text-xl max-sm:text-md max-w-[1600px]">
          {caseStudiesData[i].challengesAndObjectives}
        </span>
      </div>

      <div className="flex flex-col gap-5 px-24 max-md:px-10 max-sm:px-6 max-md:py-10 py-16 relative overflow-x-clip">
        {/* blue gradient */}
        <div className="bg-[#627ECA] opacity-20 max-sm:blur-[130px] blur-[150px] h-[50%] w-[65%] top-[23%] max-sm:top-[40%] min-w-[400px] absolute left-[-25%] z-[-2]"></div>
        {/* middle white gradient */}
        <div className="bg-gradient-to-b from-[#D9D9D900] to-[#D9D9D9] opacity-20 max-sm:blur-[130px] blur-[100px] max-sm:blur-[50px] max-sm:left-5 max-sm:h-[400px] h-[300px] w-[60%] top-[3%] max-sm:top-[1%] min-w-[400px] absolute left-[20%] z-[-2]"></div>
        <span className="font-bold text-[#627ECA] text-4xl max-md:text-3xl z-[-1]">
          Marketing Strategy
        </span>
        <span className="font-md text-lg min-xl:text-xl max-sm:text-md max-w-[1600px] z-[-1]">
          {caseStudiesData[i].marketingStrategy}
        </span>
        {caseStudiesData[i].projectName === "BLOCK9" &&
          (screenWidth < 770 ? (
            <SimpleSlider>
              <div className="sm:w-full md:w-1/2 lg:w-1/3 max-w-[500px]">
                <img loading="lazy" src="/marketing strategy/1.png" />
              </div>

              <div className="sm:w-full md:w-1/2 lg:w-1/3 max-w-[500px]">
                <img loading="lazy" src="/marketing strategy/2.png" />
              </div>

              <div className="sm:w-full md:w-full lg:w-1/3 max-w-[500px]">
                <img loading="lazy" src="/marketing strategy/3.png" />
              </div>
            </SimpleSlider>
          ) : (
            <div className="flex z-[-1] flex-wrap justify-center *:p-10 max-md:p-8 max-sm:p-0 items-center mt-6 max-md:mt-6 max-sm:mt-0">
              <div className="sm:w-full md:w-1/2 lg:w-1/3 max-w-[500px]">
                <img loading="lazy" src="marketing strategy/1.png" />
              </div>

              <div className="sm:w-full md:w-1/2 lg:w-1/3 max-w-[500px]">
                <img loading="lazy" src="marketing strategy/2.png" />
              </div>

              <div className="sm:w-full md:w-full lg:w-1/3 max-w-[500px]">
                <img loading="lazy" src="marketing strategy/3.png" />
              </div>
            </div>
          ))}
        {/* blue gradient */}
        <div className="bg-[#627ECA] opacity-20 max-sm:blur-[130px] blur-[150px] h-[50%] w-[65%] top-[23%] max-sm:top-[40%]  min-w-[400px] absolute right-[-25%] z-[-2]"></div>
      </div>

      <OtherCaseStudies hide={i} />
      <ReadyToStart />
      <Footer />
    </div>
  );
}
