export default function ReadyToStart(){
    return <div className="overflow-x-clip flex flex-col items-center py-28 max-sm:py-16">
        <div className="relative flex justify-center font-extrabold text-[9vw] max-sm:text-[70px] mb-16 w-screen z-[-1]">
            {/* golden gradient */}
            <div className="bg-[#FFC385] opacity-20 max-sm:blur-[180px] blur-[200px] h-[180%] w-[40%] min-w-[400px] absolute left-[-20%]"></div>

            {/* outlined shadow(barely visible) */}
            <span className="blur-[8.4px] overflow-x-hidden max-md:blur-[6px] max-sm:blur-[4px] opacity-10 max-md:opacity-[0.05] whitespace-nowrap absolute top-[-30%] font-outline-4 text-transparent">
                READY &nbsp;TO
            </span>

            <span className="blur-[8.4px] overflow-x-hidden max-md:blur-[6px] max-sm:blur-[4px] opacity-20 whitespace-nowrap">
                READY &nbsp;TO
            </span>

            <span className="absolute top-[35%] text-[#5F74AC] font-playfair z-[-1]">
                START?
            </span>

            {/* blue gradient */}
            <div className="bg-[#627ECA] opacity-20 max-sm:blur-[130px] blur-[150px] h-[180%] w-[40%] min-w-[400px] absolute right-[-20%]"></div>
        </div>

        <a href="https://calendly.com/visionarystudios/30min">
            <div className='pointer-events-auto rounded-[57px] bg-[#FFFFFF0D] py-4 px-14 text-[15px] font-bold hover:text-black hover:bg-[#FFFFFFD9] cursor-pointer mt-2'>
                LET’S CHAT!
            </div>
        </a>
    </div>
}