import SimpleSlider from "./SimpleSlider";
import React, { useState, useEffect } from "react";

export default function Testimonials(){
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeW = () => setScreenWidth(window.innerWidth);
    
        window.addEventListener("resize", resizeW); // Update the width on resize
    
        return () => window.removeEventListener("resize", resizeW);
    });
    
    return <div id="testimonials" className="mt-32 increase-margin-on-collapse flex justify-center scroll-m-32">
        <div className="flex flex-col max-w-[1200px] w-full">
            <div className="flex flex-col font-bold px-8 max-sm:px-0">
                <span className="text-[#FFFFFF80] text-xl hide-testimonials">
                    Testimonials
                </span>

                <div className="flex flex-col max-md:flex-wrap max-md:items-start style-testimonial">
                    <span className="font-playfair text-8xl text-[#CDC8B6] mt-2 ">
                        What they 
                    </span>

                    <span className="font-playfair text-8xl text-[#CDC8B6] mt-2">
                        say?
                    </span>
                </div>
            </div>

            { screenWidth < 770 ? 
                <SimpleSlider>
                    {TestimonialsColumn1().map((testimonial) => testimonial)}
                    {TestimonialsColumn2().map((testimonial) => testimonial)}
                    {TestimonialsColumn3().map((testimonial) => testimonial)}
                </SimpleSlider>
                :
                <div className="flex justify-center flex-wrap gap-4 mt-14 max-sm:mt-7 *:w-[32%] *:gap-4 *:min-w-[300px] *:max-[696px]:w-full px-8">
                    <div className="flex flex-col max-[1013px]:w-[45%]"> 
                        {TestimonialsColumn1().map((testimonial) => testimonial)}
                    </div>

                    <div className="flex flex-col relative bottom-16 max-[1013px]:w-[45%] max-[696px]:bottom-0">
                        {TestimonialsColumn2().map((testimonial) => testimonial)}
                    </div>

                    <div className="flex flex-col relative bottom-56 max-[1013px]:bottom-0  max-[1013px]:w-full  max-[1013px]:flex-row max-[696px]:flex-col  *:max-[1013px]:w-[45%] *:max-[696px]:w-full  max-[1013px]:justify-center  max-[1013px]:items-start">
                        {TestimonialsColumn3().map((testimonial) => testimonial)}
                    </div>
                </div>
            }
        
        </div>
    </div>
}


function TestimonialsColumn1(){
    return [
        <div className="z-[-3] relative flex gap-5 flex-col bg-gradient-to-br from-[#FFFFFF33] to-[#FFFFFF00] rounded-[24px] px-6 py-10 testimonial-box">
            <img loading="lazy"  className="absolute top-20 h-[120px]" src="testimonials/quotes.png"/>
            <img loading="lazy"  className="z-[-1] h-[90px] w-[90px] rounded-full" src="testimonials/1.png"/>

            <span className="opacity-50">
                Actually, working with Visionary as a client for Leimana is a blessing. Great marketing campaign crafting, and great people hired either for marketing or for Discord roles. I've loved how you guys scheduled every post that we need to post and when, etc. Would definitely recommend Visionary to everyone.
            </span>

            <span className="text-2xl font-playfair">
                The Rite
            </span>
        </div>

        ,

        <div className="z-[-3] relative flex gap-5 flex-col bg-gradient-to-br from-[#FFFFFF33] to-[#FFFFFF00] rounded-[24px] px-6 py-10 testimonial-box">
            <img loading="lazy"  className="absolute top-20 h-[120px]" src="testimonials/quotes.png"/>
            <img loading="lazy"  className="z-[-1] h-[90px] w-[90px] rounded-full" src="testimonials/4.png"/>

            <span className="opacity-50">
                It’s been great; we wouldn't have done an airdrop without you, which really increases our chances of selling out. I appreciate how you are specific about things and provide us with clear instructions and templates.
            </span>

            <span className="text-2xl font-playfair">
                Sorcerer
            </span>
        </div>
    ];
}

function TestimonialsColumn2(){
    return [
        <div className="z-[-3] relative flex gap-5 flex-col bg-gradient-to-br from-[#FFFFFF33] to-[#FFFFFF00] rounded-[24px] px-6 py-10 testimonial-box">
        <img loading="lazy"  className="z-[-1] absolute top-20 h-[120px]" src="testimonials/quotes.png"/>
        <img loading="lazy"  className="h-[90px] w-[90px] rounded-full" src="testimonials/2.png"/>

        <span className="opacity-50">
            To be honest, it's so much better than I expected. You really make a difference; you're taking me from A to Z in an extraordinary way. I'm very thankful for that.
        </span>

        <span className="text-2xl font-playfair">
            Nsaneee
        </span>
    </div>

    ,

    <div className="z-[-3] relative flex gap-5 flex-col bg-gradient-to-br from-[#FFFFFF33] to-[#FFFFFF00] rounded-[24px] px-6 py-10 testimonial-box">
        <img loading="lazy"  className="absolute top-20 h-[120px]" src="testimonials/quotes.png"/>
        <img loading="lazy"  className="z-[-1] h-[90px] w-[90px] rounded-full" src="testimonials/5.png"/>

        <span className="opacity-50">
            Baki is well-spoken, thoughtful and meticulous in his delivery. During calls and conversations he has displayed his keen ability to build strategies that are crafted around an individual's strengths and weaknesses - a testament to his understanding on how to navigate the Web3 space. "
        </span>

        <span className="text-2xl font-playfair">
            Ambush
        </span>
    </div>

    ];
}


function TestimonialsColumn3(){
    return [
        <div className="z-[-3] relative flex gap-5 flex-col bg-gradient-to-br from-[#FFFFFF33] to-[#FFFFFF00] rounded-[24px] px-6 py-10 testimonial-box">
            <img loading="lazy"  className="absolute top-20 h-[120px]" src="testimonials/quotes.png"/>
            <img loading="lazy"  className="z-[-1] h-[90px] w-[90px] rounded-full" src="testimonials/3.png"/>
            
            <span className="opacity-50">
                Bro i am not even evaluating you!! Coz at the back of my mind, i am not doubting your ability at all! You are a GOAT! Simple. I can only work with someone whom i have utter belief in!
            </span>

            <span className="text-2xl font-playfair">
                Moonkee
            </span>
        </div>

        ,

        <div className="z-[-3] relative flex gap-5 flex-col bg-gradient-to-br from-[#FFFFFF33] to-[#FFFFFF00] rounded-[24px] px-6 py-10  max-[1013px]:bottom-16 max-[885px]:bottom-28 max-[696px]:bottom-0 testimonial-box">
            <img loading="lazy"  className="absolute top-20 h-[120px]" src="testimonials/quotes.png"/>
            <img loading="lazy"  className="z-10 h-[90px] w-[90px] rounded-full" src="testimonials/6.png"/>

            <span className="opacity-50">
                Working with Visionary Studios has been an absolute game-changer for our marketing strategy. Baki and his team were incredibly hands-on, offering invaluable insights and challenging our ideas in the most constructive ways possible. Their ability to provide a solid structure and effectively manage the implementation of our marketing campaign was instrumental in our success. Thanks to their expertise and dedication, we were able to plan our goals with precision and efficiency. 
            </span>

            <span className="text-2xl font-playfair">
                Apeoid
            </span>
        </div>
    ];
}