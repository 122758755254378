import { InView } from "react-intersection-observer";
import { useRef, useState, useEffect } from "react";

export default function Section2(){
    const [unmuteButtonVisible, setUnmuteButtonVisible] = useState(true);
    const [videoVisible, setVideoVisible] = useState(false);
    const videoRef = useRef();

    useEffect(() => {
        if(videoVisible){
            window.addEventListener("focus", playVideo);
            window.addEventListener("blur", stopVideo);    
        }
        
        return () => {
            window.removeEventListener("focus", playVideo);
            window.removeEventListener("blur", stopVideo)
        }
    },[videoVisible]);


    function playVideo(){
        videoRef.current.play();
    }

    function stopVideo(){
        videoRef.current.play().then(()=>{
            videoRef.current.pause();
        }).catch((err)=>{
            console.log(err);
        });
    }

    function unmuteVideo(){
        videoRef.current.muted = false;
    }

    return <div className='flex max-[1200px]:flex-col py-20 max-sm:py-6 px-8 justify-center items-center mt-8 max-sm:mt-0 border-dashed border-y-2 border-[#627ECA59]'>
        <InView as="div" onChange={(inView, entry) => {if(inView){setVideoVisible(true); playVideo()} else{setVideoVisible(false); stopVideo()}}}>
            <div className="relative z-[-1]">
                <video preload="metadata" controls={false}  ref={videoRef} loop muted className='w-[60vw] max-w-[800px] rounded-[12px] *:flex  max-[1200px]:w-full object-contain' src='section 2.mp4'/>
                { 
                    unmuteButtonVisible ? <div onClick={()=> {setUnmuteButtonVisible(false); unmuteVideo()}} className="absolute right-7 bottom-4 rounded-full bg-[#000000] p-3 pointer-events-auto"><img loading="lazy"  className="h-[15px]" src="muted.png" /></div> : <></>
                }
            </div>
        </InView>
        <div className='*:max-[1200px]:items-center *:p-3 max-[1200px]:gap-2 max-[1200px]:mt-3 flex flex-col max-[1200px]:flex-wrap  ml-14 max-[1200px]:ml-0 *:flex *:flex-col  *:py-3 '>
            <div>
                <span className='font-medium max-sm:text-[2.7rem] text-[2.9rem] bg-gradient-to-l from-[#627ECA_20%] to-[#9BB2F0_100%] text-transparent bg-clip-text'>
                20+
                </span>

                <span className='text-[1.1rem] opacity-50 z-[-1] mt-[-8px]'>
                PROJECTS SOLD OUT
                </span>
            </div>

            <div>
                <span className='font-medium max-sm:text-[2.7rem] text-[2.9rem] bg-gradient-to-l from-[#627ECA_20%] to-[#9BB2F0_100%] text-transparent bg-clip-text'>
                40M+
                </span>

                <span className='text-[1.1rem] opacity-50 z-[-1]  mt-[-8px]'>
                SOCIAL MEDIA IMPRESSIONS
                </span>
            </div>

            <div>
                <span className='font-medium max-sm:text-[2.7rem] text-[2.9rem] bg-gradient-to-l from-[#627ECA_20%] to-[#9BB2F0_100%] text-transparent bg-clip-text'>
                $6.5M+
                </span>

                <span className='text-[1.1rem] opacity-50 z-[-1]  mt-[-8px]'>
                RAISED FOR CLIENTS
                </span>
            </div>

            <div>
                <span className='font-medium max-sm:text-[2.7rem] text-[2.9rem] bg-gradient-to-l from-[#627ECA_20%] to-[#9BB2F0_100%] text-transparent bg-clip-text'>
                220K+
                </span>

                <span className='text-[1.1rem] opacity-50 z-[-1]  mt-[-8px]'>
                COMMUNITY MEMBERS
                </span>
            </div>
        </div>
    </div>;
}