const caseStudiesData = [
  {
    bannerImage: "bg-case-studies-0",
    projectName: "BLOCK9",
    description:
      "Block9 has created BidHub (beta), an auction based sales platform. BidHub offers a versatile sales platform that benefits sellers and buyers. It is capable of optimising sales for NFTs, physical assets or services, all on-chain.",
    responsibilities: ["Marketing", "Project Management"],
    campaignLength: "53 DAYS",
    nftSold: 3333,
    totalRaised: "$300k",
    challengesAndObjectives:
      "Block9 had previously marketed for over 9+ months with no signs of success. The problems lied within the value proposition, branding & lack of campaign direction. The main objective upon being onboarded was to strategise a cohesive campaign that highlighted the core values & strengths of Block9 and its founding team as well as direct a speculative storyline & overall branding theme.",
    marketingStrategy:
      "Visionary Studios was responsible for crafting a 53 day marketing campaign which encapsulated ambassadors as well as an airdrop campaign. Block9 utilised Visionaries full suite of connections to over 100+ founders & influencers which ultimately when combined with a well structured list of micro strategies lead the project to 30-40x its initial engagement analytics and therefore allow the project to achieve success story",
  },
  {
    bannerImage: "bg-case-studies-1",
    projectName: "Phantom Mages",
    description:
      "Phantom Mages have built a risk-based idle game involving strategy and adventure. Phantom mages triumphed with a 20 second sellout during their whitelist phase.",
    responsibilities: ["Marketing", "Advisory"],
    campaignLength: "7 weeks",
    nftSold: 3333,
    totalRaised: "$220k",
    challengesAndObjectives:
      "Phantom Mages encountered an error during their presale phase that prevented them from conducting presales for their mint. Visionary successfully managed to sell out a supply of 3,333 units despite operating in a challenging market without any presale involvement. This remarkable achievement was facilitated by an exceptionally effective airdrop campaign, which generated significant hype for the project and led to a rapid sellout in just 20 seconds.",
    marketingStrategy:
      "Visionary provided strategic guidance to Phantom Mages by optimizing their social media presence, resulting in a remarkable 400% surge in engagement. Additionally, Visionary orchestrated a highly effective airdrop campaign, enlisting the support of over 150 influencers who willingly promoted the project without charge.",
  },
  {
    bannerImage: "bg-case-studies-2",
    projectName: "Nexi Labs",
    description:
      "Nexilabs is an all-in one Defi platform that integrated Solana wallets into discord. Nexilabs is responsible for coming out 1st place in Magic Eden's Hackaton in October 2022. Nexilabs tools have been implemented in over 170+ Discord servers.",
    responsibilities: ["Marketing", "Project Management"],
    campaignLength: "5 weeks",
    nftSold: 3333,
    totalRaised: "300k+",
    challengesAndObjectives:
      "Nexilabs faced challenges on the marketing front due to the lack of artwork, relying solely on the utility of the project. The main objective was to provide proof of concept (POC) to increase sentiment and attract more buyers for the mint. Strategic partnerships, showcasing, and implementing the tools were key pre-mint actions that ensured a smooth sellout.",
    marketingStrategy:
      "We successfully generated bullish sentiment through the aforementioned proof of concept by providing numerous servers with our products for free as a marketing strategy. Additionally, we showcased these tools live to there community via Discord and Twitter.",
  },
];

export default caseStudiesData;
