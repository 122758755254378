export default function Clients(){
    return <div className="mt-40 increase-margin-on-collapse flex justify-center relative overflow-x-clip">
        {/* background gradient */}
        <div className="absolute w-[70vw] h-[70vw] rounded-full bg-[#627ECA] opacity-15 blur-[200px] top-[-15vw]"></div>

        {/* background image */}
        <img loading="lazy"  src="philosophy.png" className="w-screen min-h-[500px] object-cover z-[-1]"/>

        <div className="absolute flex flex-col justify-center items-center h-full px-4 z-[-1]">
            <span className="font-semibold text-7xl max-sm:text-4xl opacity-50 text-[#CDC8B6]">
                Our
            </span>

            <span className="font-bold text-[8rem] max-sm:text-[4.5rem] font-playfair italic text-[#CDC8B6] leading-[90%]">
                Philosophy
            </span>

            <span className="font-medium max-w-[840px] text-2xl max-sm:text-lg text-center mt-10 text-[#ffffff80] max-sm:font-normal">
                Taking our clients through a customized process from point A to Z, not only will you see <span className="text-white">your project grow exponentially</span>, but you will also witness your personal development in cohesion.
            </span>
        </div>
    </div>
}