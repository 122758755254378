import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Navbar from "./Navbar";

export default function Landing(){
    var settings = {
      dots: false,
      infinite: true,
      speed: 7000,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 5,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 4,
          }
        },

        {
          breakpoint: 600,
          settings: {
            slidesToShow: 3,
          }
        },
      ]
    };

    const [menuOpen, setMenuOpen] = useState(false);

    return <div id="home" className='min-h-screen bg-hero bg-contain max-md:bg-cover bg-center bg-no-repeat flex flex-col justify-between max-sm:justify-start pb-4 landing-query-pb'>

        {menuOpen ? <div className="h-[88.1px]"></div> : <></>}

        <Navbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} selectedItem={1} />

        {/* auto top spacing */}
        <div className='pt-10 flex flex-col max-sm:justify-between max-sm:grow'>

          {/* for spacing */}
          <div></div>

          <div className="landing-padding-bottom">
            {/* content */}
            <div className='text-[3.8rem] landing-query-5rem max-md:text-[3rem] max-sm:text-[8vw] leading-[110%] flex flex-col w-fit mx-auto items-end max-sm:items-center font-bold px-5'>
              <div className={`opacity-50 ${menuOpen ? "z-[-1]" : ""}`}>
                <span>
                  TRANSFORM
                </span>

                <span className="pl-8 max-sm:pl-4 font-playfair bg-gradient-to-r from-white to-[#FFFFFF32_120%] max-sm:to-[#FFFFFF32_180%] max-md:to-[#FFFFFF32_140%] text-transparent bg-clip-text">
                  VISIONS
                </span>
              </div>

              <div className={`${menuOpen ? "z-[-1]" : ""}`}>
                <span className='opacity-50'>
                  INTO
                </span>

                <span className="pl-8 max-sm:pl-4 font-playfair mr-24 max-sm:mr-0 bg-gradient-to-r from-white to-[#FFFFFF96] text-transparent bg-clip-text">
                  REALITY.
                </span>
              </div>
            </div>
            
            <div className="flex flex-col w-fit mx-auto text-[16px]  landing-query-30px items-end max-md:items-center mt-7 max-sm:mt-9 px-5 text-center">
              <span className={`opacity-50 ${menuOpen ? "z-[-1]" : ""}`}>
                Ideation to mint creation, we ensure every step of the way is a step towards success
              </span>

              <span className={`${menuOpen ? "z-[-1]" : ""} mr-24 max-sm:mr-0 text-[19px] landing-query-35px max-sm:mt-2`}>
                Venture with a team of experts that can turn your&nbsp;
                <span className="relative whitespace-nowrap">
                    dreams into reality.
                  <div className="h-1 w-[190px] bg-[#627ECA] absolute bottom-[-3] right-[-5px] rounded-[12px] skew-y-[-2deg]"></div>
                </span>
              </span>

            </div>

            <div className='*:pointer-events-auto *:cursor-pointer flex max-sm:flex-col max-sm:gap-6 items-center justify-center gap-10 mt-8 font-semibold whitespace-nowrap landing-query-135rem'>
              <div className='bg-[#FFFFFF0D] py-3 px-12 rounded-[57px] hover:text-gray-400'>
                LEARN MORE
              </div>

              <div className=' bg-gradient-to-tl from-[#40548A] to-[#0C1016] py-3 px-12 rounded-[57px] hover:text-gray-400'>
                GET STARTED
              </div>
            </div>

          </div>
         
          {/* footer */}
          <div className='flex flex-col justify-center mt-16 overflow-hidden items-center max-sm:mb-16'>
            <span className={`text-xl opacity-50 font-semibold whitespace-nowrap ${menuOpen ? "z-[-1]" : ""}`}>
              TRUSTED BY THE BEST.
            </span>
            
            <Slider className={`landing-slider w-[130%] relative right-[-1%] mt-5 flex ${menuOpen ? "z-[-1]" : ""}`} {...settings}>

              <div>
                <img loading="lazy"  src='trusted by/1.png' />
              </div>

              <div>
                <img loading="lazy"  src='trusted by/2.png' />
              </div>

              <div>
                <img loading="lazy"  src='trusted by/3.png' />
              </div>

              <div>
                <img loading="lazy"  src='trusted by/4.png' />
              </div>

              <div>
                <img loading="lazy"  src='trusted by/5.png' />
              </div>

            </Slider>
            
          </div>
        </div>
      </div>;

}