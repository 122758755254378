import React, { useState, useEffect } from "react";
import SimpleSlider from "../components/SimpleSlider";

export default function Services(){
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeW = () => setScreenWidth(window.innerWidth);
    
        window.addEventListener("resize", resizeW); // Update the width on resize
    
        return () => window.removeEventListener("resize", resizeW);
    });


    return <div id="services" className="mt-40 overflow-x-clip mb-28 scroll-m-32 increase-margin-on-collapse">
        <div className="text-6xl max-md:text-4xl max-sm:text-3xl flex justify-center items-center mt-20 w-full relative z-[-1]">
            {/* golden gradient */}
            <div className="bg-[#FFC385] opacity-20 max-sm:blur-[180px] blur-[200px] h-[500%] w-[50%] min-w-[500px] absolute left-[-25%] top-[5%]"></div>

            <div>
                <div className="move-in-text-1 relative">
                    <span className="opacity-50 font-semibold">
                        customized
                    </span>
                    &nbsp;
                    <span className="font-playfair mb-1.5 italic font-bold">
                        strategies
                    </span>
                </div>
            </div>

            <div className="absolute">
                <div className="move-in-text-2 relative">
                    <span className="opacity-50 font-semibold">
                        instant
                    </span>
                    &nbsp;
                    <span className="font-playfair mb-1.5 italic font-bold">
                        results
                    </span>
                </div>
            </div>

            <div className="absolute">
                <div className="move-in-text-3 relative">
                    <span className="opacity-50 font-semibold">
                        endless
                    </span>
                    &nbsp;
                    <span className="font-playfair mb-1.5 italic font-bold">
                        connections
                    </span>
                </div>
            </div>

            {/* blue gradient */}
            <div className="bg-[#627ECA] opacity-20 max-sm:blur-[130px] blur-[150px] h-[500%] w-[50%] min-w-[400px] absolute right-[-25%]"></div>
        </div>

        <div className="flex justify-center gap-5 mt-6">
            <div className="relative z-[-1]"> 
                <div className="w-[11px] h-[11px] bg-[#D9D9D9] rounded-full absolute top-0 opacity-15 z-[-1]"></div>
                <div className="opacity-50">
                    <div className="w-[11px] h-[11px] bg-[#D9D9D9] rounded-full move-in-text-1 z-[-1]"></div>
                </div>
            </div>

            <div className="relative z-[-1]">
                <div className="w-[11px] h-[11px] bg-[#D9D9D9] rounded-full absolute top-0 opacity-15 z-[-1]"></div>
                <div className="opacity-50">
                    <div className="w-[11px] h-[11px] bg-[#D9D9D9] rounded-full move-in-text-2 z-[-1]"></div>
                </div>
            </div>

            <div className="relative z-[-1]">
                <div className="w-[11px] h-[11px] bg-[#D9D9D9] rounded-full absolute top-0 opacity-15 z-[-1]"></div>
                <div className="opacity-50">
                    <div className="w-[11px] h-[11px] bg-[#D9D9D9] rounded-full move-in-text-3 z-[-1]"></div>
                </div>
            </div>
        </div>

        <div className="font-playfair justify-center text-4xl mt-14 text-[#CDC8B6] italic font-bold visible-on-collapse hidden">Our Services</div>

        { screenWidth < 770 ? 
            <SimpleSlider>
                {ServicesInner().slice(1).map((Service) => Service)}
            </SimpleSlider>
            :
            <div className="flex flex-wrap mt-20 px-16 max-[1164px]:px-5 *:p-3 *:h-[390px] relative z-[-1] max-w-[1700px] mx-auto">
                {/* blue gradient */}
                <div className="bg-[#627ECA] opacity-20 max-sm:blur-[110px] blur-[150px] h-[500px] w-[50%] min-w-[400px] absolute left-[-25%] top-[25%]"></div>

                {/* bottom blue gradient */}
                <div className="bg-[#627ECA] opacity-20 max-sm:blur-[130px] blur-[150px] h-[500px] w-[50%] min-w-[400px] absolute right-[-25%] bottom-[10%]"></div>

                {ServicesInner().map((Service) => Service)}
            </div>
        }

    </div>
}

function ServicesInner(params) {
    return [
        <div className="w-1/3 max-[770px]:w-full max-[770px]:h-auto service-box">
            <div className="w-full h-full flex flex-col justify-between p-12 max-[736px]:p-6 rounded-[23px]">
                <span className="font-bold text-3xl max-[1164px]:text-2xl opacity-50">
                    SERVICES
                </span>

                <span className="font-semibold text-[#CDC8B6] font-playfair text-7xl max-[1164px]:text-6xl">
                    What we offer
                </span>
            </div>
        </div>
        ,

        <div className="w-2/3 max-[770px]:w-full service-box">
            <div className="rounded-[23px] w-full h-full flex justify-end relative bg-gradient-to-br from-[#627ECA00] to-[#62626217]">
                <img loading="lazy"  className="w-[900px] object-cover rounded-[23px]" src="services/1.png"/>

                <div className="flex flex-col absolute left-10 bottom-10">
                    <span className="text-[#627ECA] font-semibold text-3xl">
                        SOCIAL OPTICS
                    </span>

                    <span className="text-[#FFFFFF80] max-w-[400px] mt-4">
                        Struggling with engagement? Your external optics could be the reason why. From cleaning up your channel structure and crafting engaging Discord announcements to optimising your Twitter page, we can do it all.
                    </span>
                </div>
            </div>
        </div>

        ,

        <div className="w-1/3 min-w-[340px] max-[1164px]:w-1/2 max-[770]:w-full service-box">
            <div className="rounded-[23px] w-full h-full relative bg-gradient-to-br from-[#627ECA00] to-[#62626217]">
                <img loading="lazy"  className="absolute rounded-[23px]" src="services/2.png"/>

                <div className="flex flex-col absolute bottom-10 left-7">
                    <span className="text-[#627ECA] font-semibold text-3xl">
                        BRANDING
                    </span>

                    <span className="text-[#FFFFFF80] max-w-[85%] mt-4">
                        Understanding your vision and properly establishing yourself to grow your IP is a must. We first understand our clients vision before moving into strategising.
                    </span>
                </div>
            </div>
        </div>

        ,

        <div className="w-1/3 min-w-[340px] max-[1164px]:w-1/2 max-[770]:w-full service-box">
            <div className="rounded-[23px] w-full h-full relative bg-gradient-to-br from-[#627ECA00] to-[#62626217]">
                <img loading="lazy"  className="absolute rounded-[23px]" src="services/3.png"/>

                <div className="flex flex-col absolute bottom-10 left-7">
                    <span className="text-[#627ECA] font-semibold text-3xl">
                        PRESALE STRATEGY
                    </span>

                    <span className="text-[#FFFFFF80] max-w-[85%] mt-4">
                        Learn how to successfully run your presale, no more rejections.
                    </span>
                </div>
            </div>
        </div>

        ,

        <div className="w-1/3 min-w-[340px] max-[1164px]:w-1/2 max-[770]:w-full service-box">
            <div className="rounded-[23px] w-full h-full relative bg-gradient-to-br from-[#627ECA00] to-[#62626217]">
                {/* round gradient */}
                <div className="absolute w-[360px] h-[360px] rounded-full left-4 top-[-53px] bg-[#23459F4D] blur-[100px]"></div>
                <img loading="lazy"  className="absolute rounded-[23px]" src="services/4.png"/>

                <div className="flex flex-col absolute bottom-10 left-7">
                    <span className="text-[#627ECA] font-semibold text-3xl max-w-[90%]">
                        COLLABORATION STRATEGY
                    </span>

                    <span className="text-[#FFFFFF80] max-w-[85%] mt-4">
                        Want to grow your discord fast the right way? We've mastered collaboration techniques and strategies that just work.
                    </span>
                </div>
            </div>
        </div>

        ,

        <div className="w-2/3 max-[1164px]:w-1/2 max-[470]:w-full service-box">
            <div className="rounded-[23px] w-full h-full relative bg-gradient-to-br from-[#627ECA00] to-[#62626217]">
                <img loading="lazy"  className="object-cover rounded-[23px] max-[1164px]:h-[230px]" src="services/5.png"/>

                <div className="flex flex-col absolute bottom-10 left-10">
                    <span className="text-[#627ECA] font-semibold text-3xl">
                        MARKETING
                    </span>

                    <span className="text-[#FFFFFF80] max-w-[85%] mt-4 max-md:mt-5">
                        Most individuals and projects lack the required experience needed to successfully market a Web3 project. We will cultivate a custom-tailored marketing campaign, including all the ideating and caption creating.
                    </span>
                </div>
            </div>
        </div>

        ,

        <div className="w-1/3 min-w-[340px] max-[1164px]:w-full service-box">
            <div className="rounded-[23px] w-full h-full relative bg-gradient-to-br from-[#627ECA00] to-[#62626217]">
                <img loading="lazy"  className="object-cover rounded-[23px]" src="services/6.png"/>

                <div className="flex flex-col absolute bottom-10 left-7">
                    <span className="text-[#627ECA] font-semibold text-3xl">
                        LAUNCHPAD STRATEGY
                    </span>

                    <span className="text-[#FFFFFF80] max-w-[85%] mt-4">
                        So overlooked, yet so important. Understanding how to consider macro factors in the market and properly setting up your launch phases when preparing to mint is crucial.
                    </span>
                </div>
            </div>
        </div>
    ];
}
