import SimpleSlider from "./SimpleSlider"
import React, { useState, useEffect } from "react";
import caseStudiesData from "../data/case studies";

export default function CaseStudies({hide}){
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeW = () => setScreenWidth(window.innerWidth);
    
        window.addEventListener("resize", resizeW); // Update the width on resize
    
        return () => window.removeEventListener("resize", resizeW);
    });

    return <div className="mt-1 max-sm:mt-3 max-sm:mb-5">
        <span className="flex justify-center font-bold text-[#CDC8B6] text-5xl max-md:text-4xl max-sm:text-3xl mb-12 max-sm:mb-3">
            Other Case Studies
        </span>


        { screenWidth < 770 ? 
            <SimpleSlider>
                { 
                    (() => {
                        let container = [];

                        for(var i = 0; i < 3; i++){
                            if(i != hide){
                                container.push(
                                    <StudyColumn1 index={i} />
                                )
                            }
                        }

                        return container;
                    })()
                }
            </SimpleSlider>
            :
            <div className="flex max-[1170px]:flex-col *:max-[1170px]:w-full max-[1170px]:gap-10 items-center justify-center px-4">
                { 
                    (() => {
                        let container = [];

                        for(var i = 0; i < 3; i++){
                            if(i != hide){
                                container.push(
                                    <StudyColumn1 index={i} />
                                )
                            }
                        }

                        return container;
                    })()
                }
            </div>
        }
    </div>
}

function StudyColumn1({index}){
    return  <div className="w-1/2 max-w-[700px] max-[900px]:w-full px-2.5 other-case-study">
        <div className="flex justify-end z-[-1] bg-[#0D0D0D] rounded-[25px] relative min-h-[320px]">
            {/* background image */}
            <img loading="lazy"  className="w-[450px] rounded-[25px] right-0" src={`case studies/${index}.png`}/>

            {/* fade bg image */}
            <div className="absolute rounded-[25px] w-full h-full bg-gradient-to-l from-transparent to-[#0B0B0B_70%]"></div>

            <div className="absolute pb-12 max-sm:pb-6 px-7 left-0 flex flex-col justify-between h-full">
                <div className="mt-10 flex flex-col">
                    <span className="font-inter text-4xl max-sm:text-2xl text-[#C0CBEA] font-bold">
                        {caseStudiesData[index].projectName}
                    </span>

                    <span className="opacity-50 mt-5 max-sm:mt-2 text-lg max-[480px]:text-sm leading-[160%] perma-line-clamp">
                        {caseStudiesData[index].description}
                    </span>

                    <div className="*:whitespace-nowrap flex max-sm:text-xs font-semibold text-sm gap-3 mt-5 *:text-[#C0CBEA] *:rounded-[30px] *:border *:border-[#627ECA] *:py-1.5 *:px-5">
                        <div>
                            {caseStudiesData[index].responsibilities[0]}
                        </div>

                        <div>
                            {caseStudiesData[index].responsibilities[1]}
                        </div>
                    </div>
                </div>

                <div className="flex justify-between mt-7 max-sm:mt-3 items-center"> 
                    <a href={`/case-studies/${caseStudiesData[index].projectName.replaceAll(" ", "-")}`}>
                        <div className="bg-[#FFFFFF0D] pointer-events-auto max-sm:text-sm cursor-pointer hover:bg-[#FFFFFF26] font-bold px-6 py-2 rounded-[57px] w-min whitespace-nowrap">
                            VIEW CASE STUDY
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
}
