export default function Footer(){
    return <div className='footer-media border-t-2 border-[#627ECA40] flex justify-between py-8 px-14 max-sm:px-8 whitespace-nowrap pb-12'>
    <div>
      <img loading="lazy"  className='h-[30px] mb-[20px]' src='/footer logo.png'/>

      <span className='text-[#ffffff80] text-lg'>
        Transform your Vision into Reality.
      </span>
    </div>

    <div className='flex flex-col-reverse footer-row-on-sm items-end footer-column-2 text-[#ffffff48] pl-4'>
      <div className='flex footer-media'>
        <span className='mr-14'>
          @2024 Visionary Studios
        </span>

        <span>
          All Rights Reserved
        </span>
      </div>

      <img loading="lazy"  className='mb-4 mr-4 pointer-events-auto cursor-pointer max-sm:scale-[0.85]' src='/x.png' />
    </div>
  </div>;
}