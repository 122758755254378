import React, { useState } from "react";
import caseStudiesData from "../data/case studies";

export default function CaseStudies(){
    const [studyIndex, setStudyIndex] = useState(0);

    return <div id="caseStudies" className="overflow-x-clip flex increase-margin-on-collapse justify-center mt-32 px-4 scroll-m-32 relative z-[-1]">
        {/* left gradient */}
        <div className="bg-[#627ECA] opacity-20 max-sm:blur-[180px] blur-[150px] h-[100%] w-[60%] min-w-[500px] absolute left-[-25%] top-[5%] z-[-2]"></div>

        {/* right gradient */}
        <div className="bg-[#627ECA] opacity-20 max-sm:blur-[180px] blur-[150px] h-[100%] w-[60%] min-w-[500px] absolute right-[-25%] top-[5%] z-[-2]"></div>

        <div className="flex gap-6 w-full max-w-[1200px] case-media">
            <div className="case-studies flex flex-col justify-end w-[40%] max-w-[600px] case-full-width min-h-[300px] bg-gradient-to-bl from-[#40548A00_0%] to-[#40548A80_50%] rounded-[23px] px-9 py-11">
                <span className="font-semibold opacity-50 text-5xl z-[-1]">
                    Case
                </span>

                <span className="font-bold font-playfair text-7xl text-[#CDC8B6] italic leading-[80%]">
                    Studies
                </span>
            </div>

            <div className="flex justify-end z-[-1] w-[60%] max-w-[600px] case-full-width bg-[#0D0D0D] rounded-[25px] relative h-[450px] case-height-query">
                {/* background image */}
                <img loading="lazy"  className="w-[450px] object-cover rounded-[25px] right-0" src={`case studies/${studyIndex}.png`}/>

                {/* fade bg image */}
                <div className="absolute left-[-5px] rounded-[25px] w-full h-full bg-gradient-to-l from-transparent to-[#070707_70%]"></div>

                <div className="absolute pb-12 max-sm:pb-6 px-7 left-0 flex flex-col justify-between h-full">
                    <div className="pt-10 flex flex-col">
                        <span className="font-inter text-4xl max-sm:text-2xl text-[#C0CBEA] font-bold">
                            {caseStudiesData[studyIndex].projectName}
                        </span>

                        <span className="opacity-50 mt-5 max-sm:mt-2 text-lg max-[480px]:text-sm leading-[160%] mr-[20%] perma-line-clamp">
                            {caseStudiesData[studyIndex].description}
                        </span>

                        <div className="flex max-sm:text-xs font-semibold text-sm gap-3 mt-5 *:text-[#C0CBEA] *:rounded-[30px] *:border *:border-[#627ECA] *:py-1.5 *:px-5">
                            <div className="whitespace-nowrap">
                                {caseStudiesData[studyIndex].responsibilities[0]}
                            </div>

                            <div className="whitespace-nowrap">
                                {caseStudiesData[studyIndex].responsibilities[1]}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-between mt-7 max-sm:mt-3 items-center"> 
                        <a href={`/case-studies/${caseStudiesData[studyIndex].projectName.replaceAll(" ", "-")}`}>
                            <div className="bg-[#FFFFFF0D] pointer-events-auto max-sm:text-sm cursor-pointer hover:bg-[#FFFFFF26] font-bold px-6 py-2 rounded-[57px] w-min whitespace-nowrap">
                                VIEW CASE STUDY
                            </div>
                        </a>

                        <div className="flex gap-3 *:cursor-pointer *:pointer-events-auto *:h-[34px] *:w-[34px] *:flex *:justify-center *:items-center *:bg-[#ffffff66] *:rounded-full *:backdrop-blur-[4px]">
                            <div onClick={() => setStudyIndex((studyIndex != 0) ? studyIndex - 1 : 2)}>
                                <img loading="lazy"  className="rotate-180 h-[16px] mr-0.5" src="arrow right.png"/>
                            </div>
                            <div onClick={() => setStudyIndex((studyIndex + 1) % 3)}>
                                <img loading="lazy"  className="h-[16px] ml-0.5" src="arrow right.png"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}   


